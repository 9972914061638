export default {
    Nav: {
        Home: 'Home',
        About: 'About',
        Game: 'Game',
        Joinus: 'Join Us',
        contactUs: 'Contact Us',
        secret:'Policy',
        terms:'Terms'
    },
    Footer: {
        cont: "Copyright {'@'} 2023 All Right Reserved XinSheng Network Co., Ltd."
    },
    commencont:{
        Coming: 'Coming soon'
    },
    contactus: {
        address:'9th Floor, Playmates Factory Building Phase 1, 1 Tin Hau Road, Tuen Mun, New Territories, HONGKONG,CHINA',
        title:'Xin Sheng Fun Games'
    },
    Aboutus:{
        about1: 'Xinsheng Network Co., Ltd., established on May 2022 and head office located in Hong Kong . China, is a comprehensive interactive entertainment enterprise with mobile game as its core and integrating research, development, operation and distribution.',
        about2: 'The core team members of the company are from world-famous game companies, have rich experience in game research and development and operation, and have been committed to taking independent innovation as the cornerstone, relying on strong technical strength and creative game design capabilities, many card, strategy, MMORPG, and Casual games are under intense research and development, and will be released to global markets such as Hong Kong, Macao and Taiwan, Japan, South Korea, Europe, the Middle East, and Southeast Asia.',
        about3: 'In the future, Xinsheng Fun will continue to develop the absolute best products possible, ones that exceed users’ expectations and bring fun, exciting entertainment and high-quality services to players everywhere.',
    },
    title:{
        title: 'Xin Sheng Fun Games',
        Keywords: 'xin sheng games,mobile games,netgame,android,apple game,ios game,publisher,overseas game,hong kong games,google play games,appstore,download,install,Samsung,huawei,oppo,vivo,meta,vr game, war game,strategy game, revenge, war strategy, free games, online games, mobile app, arabic game,RTS, MMO,world game',
        Description:'A mobile game develop and publisher。'
    },
    Secret:{
        src:'/static/policy-en.html'
    },
    Terms:{
        src:'/static/terms-en.html'
    }
}
